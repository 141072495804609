.cards {
  padding: 4rem;
  background: #fff;
}

.cards h1 {
  color: #4578b4;
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}

.cards__items {
  margin-bottom: 24px;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 1rem;
  border-radius: 10px;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 67%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 15px;
  font-weight: 700;
  color: #ffffff;
  background-color: #f41f2a;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  /* color: #252e48; */
  color: #4578b4;
  text-align: left;
  font-size: 18px;
  line-height: 24px;
}

@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}

@media only screen and (max-width: 1024px) {
  .cards__item {
    margin-bottom: 2rem;
  }
}


/* WhatsApp Floating Button Styles */

/* Floating WhatsApp button */
.whatsapp-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #25d366; /* WhatsApp green color */
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.whatsapp-button:hover {
  background-color: #128c7e; /* Darker shade on hover */
}

/* WhatsApp Popup Styles */
.whatsapp-popup {
  position: fixed;
  bottom: 70px;
  right: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.whatsapp-popup.active {
  max-height: 300px; /* Adjust the max-height as needed */
}

.whatsapp-popup-content {
  padding: 10px;
}

.whatsapp-popup-content p {
  margin: 0;
}

/* WhatsApp Close Button */
.whatsapp-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #666;
}

.note{
  display: none;
}

td:hover .note {
  display: block;
  position: absolute;
  width: 50%;
  background-color: yellow;
  color: red;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1;
} 

.app {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    /* height: 100vh; */
  }

  
  
  .content-container {
    max-width: 800px;
    width: 100%;
    padding: 20px;
  }
  .table-container {
    overflow-x: auto;
    background-color: #ddd;

  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px; /* Adjust space above the table heading */
    margin-bottom: 20px; /* Adjust space below the table */
  }
  
  thead {
    background-color: #3498db; /* Set your preferred header background color */
    color: #fff;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  tbody tr:hover {
    background-color: #f5f5f5; /* Set your preferred hover background color */
  }
  
  .content-container h3 {
    font-size: 1.5em; /* Set your preferred font size */
    color: hsl(0, 59%, 35%); /* Set your preferred text color */
    margin-bottom: 15px; /* Set the bottom margin as needed */
    text-align: center; /* Set the text alignment as needed */
    font-weight: bold; /* Set the font weight as needed */
    text-transform: uppercase; /* Optionally, apply uppercase text */
    letter-spacing: 1px; /* Optionally, add letter spacing for emphasis */
  }
  
  /* Optional: Add additional styles for hover or other interactions */
  .content-container h3:hover {
    color: #e44d26; /* Set your preferred color on hover */
    cursor: pointer;
    /* Add other hover styles as needed */
  }
  
.servicedetails__section {
    display: block;
    color: #4578b4;
    padding: 4rem;
    background: #fff;
  }
  
  .servicedetails__section h3 {
    text-align: center;
  }
  

  .servicedetails__section p {
   -webkit-font-smoothing: antialiased;
   color: var(--jfv-navy-600);
   font-family: Circular,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
   line-height: 1.5;
   font-size: 20px;
  }

  
  .servicedetails__container {
    display: block;
    flex-flow: row;
    align-items: center;
    max-width: 1120px;
    width: 90%;
    margin: 20 0 auto;
  }
  
  .servicedetails__wrapper {
    position: relative;
    padding: 50px;
    margin: 50px 0 45px;
  }

  .servicedetails__item__text {
    margin-top: -45px;
    text-align: center;
    align-content: center;
    font-size: 18px;
    line-height: 24px;
  }
  .servicedetails__item__tbl {
    border: solid;
    margin-left: 20%;
  }

  .servicedetails__item__tbl th, td {
    border: 2px solid black;
    border-style: none;
    border-radius: 10px;
  }

  .badge {
    background-color: red;
    color: white;
    padding: 0px 4px;
    text-align: center;
    border-radius: 5px;
  }
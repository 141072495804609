/* EmailComponent.css */

.email-container {
    max-width: 400px;
    margin: 20px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .input-field,
  .textarea-field {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
  }
  
  .textarea-field {
    resize: vertical;
  }
  
  .send-button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .send-button:hover {
    background-color: #45a049;
  }
  
  .dropdown-field {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
  }

  .input-section {
    margin-bottom: 15px;
  }
  
  .label {
    display: block;
    margin-bottom: 5px;
  }
  
  .error {
    border: 2px solid #dc3545; /* Red border for error */
  }
  
  .error-message {
    color: #dc3545; /* Red text for error message */
    font-size: 12px;
    margin-bottom: 5px;
    display: block;
  }
  
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans', 'PT Sans', sans-serif;
}

.home,
.services,
.pricing,
.contact,
.about {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.about {
  /* background-image: url('/images/img-1.jpg'); */
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  font-size: 100px;
}

.contact {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}


.aboutus {
  display: block;
  color: #4578b4;
  padding: 4rem;
  background: #fff;
}

.aboutus h2 {
  text-align: center;
}

.aboutus p {
 -webkit-font-smoothing: antialiased;
 color: var(--jfv-navy-600);
 font-family: Circular,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
 line-height: 1.5;
 font-size: 15px;
}

.video-container {
  border: solid;
  max-width: 100%;
  margin: 0 auto;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  
  }
  
  .video-container video {position: absolute;
  width: 100%;
  height: 100%;
  }
.pricing_section {
    padding: 100px 0 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fafafa;
  }
  .price-symbol{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }

  .pricing_container-card{
    background: linear-gradient(45deg, #0660e9 0%, #276afb 100%);
    box-shadow: 0, 6px 20px rgba(56, 125, 255, 0.2);
    width: 280px;
    height: 500px;
    text-decoration: none;
    border-radius: 4px;
  }

  .pricing_container-card:nth-child(2) {
    transform: scale(1.05);
    background:  #242424;
    margin: 0 24px;
  }
  
  .pricing_container-card:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
    color: #1c2237;
  }

  .pricing_container{
    display: flex;
    justify-content: center;
    align-content: center;
  }

  .pricing_wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin: 0 auto;
  }

  .pricing_heading {
    color: #1c2237;
    margin-bottom: 24px;
  }

  .pricing_container-cardInfo {
    display: flex;
    flex-direction: column;
    height:  500px;
    padding: 24px;
    align-items:  center;
    color:  #fff;
  }

  .pricing_container-cardInfo h3 {
    margin-bottom: 1px;
    font-size:  23px;
  }

  .pricing_container-cardInfo h4 {
    font-size:  30px;
  }

  .pricing_container-cardInfo p {
    font-size:  13px;
    margin-bottom:  23px;
  }

  .pricing_container-features  {
    margin:  16px 0 32px;
    list-style:  none;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pricing_container-features li  {
    margin-bottom:  10px;
  }

 .icon {
    margin:  24px 0;
 }

 @media screen and (max-width: 960px) {
    .pricing_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .pricing_container-card {
        width:  90%;
    }

    .pricing_wrapper {
        margin:  0 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pricing_container-card:nth-child(2) {
        transform:  scale(1);
        background:  #242424;
        margin:  24px;
    }

    .pricing_container-card:hover {
        transform: none;
    }
 }

  
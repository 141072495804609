/* WhatsAppButton.css */

.whatsapp-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000; /* Adjust the z-index as needed */
}

.whatsapp-button {
  background-color: #25d366;
  color: #fff;
  border: none;
  border-radius: 50%;
  padding: 15px;
  font-size: 20px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s;
}

.whatsapp-button:hover {
  background-color: #128c7e;
}

.whatsapp-popup {
  position: fixed;
  bottom: 70px;
  right: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.whatsapp-popup.active {
  max-height: 300px;
}

.whatsapp-popup-content {
  padding: 10px;
}

.whatsapp-popup-content p {
  margin: 0;
}

.whatsapp-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
  color: #666;
}

.Service__wrapper
{
    display: block;
    /* background-color: aquamarine; */
}

.service-container {
    /* background: url('/src/images/img-home.jpg') center center/cover no-repeat; */
    height: 65vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .service-container > h1 {
    color: #fff;
    font-size: 50px;
    margin-top: -100px;
  }
.call__now {
  padding: 50px 0;
  background: #f9f9f9; /* Set your preferred background color */
}

.callnow__container {
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.callnow__wrapper {
  position: relative;
  padding: 30px;
  margin: 30px 0;
  background-color: #438ed8;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  border-radius: 10px; /* Add rounded corners */
}

.callnow__item__text {
  color: #252e48;
  text-align: center;
  font-size: 18px;
  line-height: 1.5;
}

/* .callnow__item__text p {
  margin-bottom: 20px;
} */

.callnow__item__text a {
  text-decoration: none;
}

.callnow__item__text button {
  font-size: 16px;
}

/* Style for the buttons - assuming these are custom ButtonLink components */
.btn--wide {
  width: 200px; /* Adjust width as needed */
}

.btn--wide.primary {
  background-color: #007bff; /* Set your primary button color */
  color: #fff;
  border: none;
}

.btn--wide.primary:hover {
  background-color: #0056b3; /* Set your primary button color on hover */
}

.contact-form {
  background: hsl(189, 41%, 73%);
  padding: 5px 0;
}

.form-container {
  max-width: 700px;
  width: 90%;
  margin: 0 auto;
}

.form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.form-group {
  flex: 1 0 48%; /* Adjust width as needed, considering some space between items */
}

.form-group label {
  display: block;
  margin-bottom: 8px;
}

.form-group input,
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-group button {
  width: 100%;
  margin-left: 10px;
  padding: 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-group button:hover {
  background-color: #0056b3;
}

.form-group > .dropdown-field {
  width: 100%;
  margin-left: 10px;
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
}
.errormessage{
  text-align: center;
  margin-left: 2px;
  color: red;
  font-size: 13px;
}
.quotedesc{
  padding-top: -20px;
  font-size: 13px;
  margin-bottom: 20px;
}

